import config from './../config'
var defaultState = { 

        agents : [],
        isOpen: [], //for active default menu
        isTrigger: [], //for active default menu, set blank for horizontal
        ...config,
        isFullScreen: false, // static can't change
}

export default defaultState
