import * as actionTypes from '../actions/actionTypes';  
import initialState from '../initialState';

function agentReducer(state = initialState, action) {
  console.log(action)
  let trigger = [];
  let open = [];
  let nextState = {}
  switch (action.type) {
      case actionTypes.GET_AGENTS_SUCCESS:
        nextState = {
          ...state,
          agents :action.value
        }
      return nextState || state

      case actionTypes.GET_AGENTS_ERROR:
        nextState = {
          ...state,
          agents : []
        }
      return nextState || state
      
      /*case types.SET_CURRENT_TRIP:
        nextState = {
           ...state,
           cov_current_trip : action.value
        }
      return nextState || state
      */

  //template
  case actionTypes.COLLAPSE_MENU:
    return {
        ...state,
        collapseMenu: !state.collapseMenu
    };
case actionTypes.COLLAPSE_TOGGLE:
    if (action.menu.type === 'sub') {
        open = state.isOpen;
        trigger = state.isTrigger;

        const triggerIndex = trigger.indexOf(action.menu.id);
        if (triggerIndex > -1) {
            open = open.filter(item => item !== action.menu.id);
            trigger = trigger.filter(item => item !== action.menu.id);
        }

        if (triggerIndex === -1) {
            open = [...open, action.menu.id];
            trigger = [...trigger, action.menu.id];
        }
    } else {
        open = state.isOpen;
        const triggerIndex = (state.isTrigger).indexOf(action.menu.id);
        trigger = (triggerIndex === -1) ? [action.menu.id] : [];
        open = (triggerIndex === -1) ? [action.menu.id] : [];
    }

    return {
        ...state,
        isOpen: open,
        isTrigger: trigger
    };
case actionTypes.NAV_CONTENT_LEAVE:
    return {
        ...state,
        isOpen: open,
        isTrigger: trigger,
    };
case actionTypes.NAV_COLLAPSE_LEAVE:
    if (action.menu.type === 'sub') {
        open = state.isOpen;
        trigger = state.isTrigger;

        const triggerIndex = trigger.indexOf(action.menu.id);
        if (triggerIndex > -1) {
            open = open.filter(item => item !== action.menu.id);
            trigger = trigger.filter(item => item !== action.menu.id);
        }
        return {
            ...state,
            isOpen: open,
            isTrigger: trigger,
        };
    }
    return {...state};
case actionTypes.FULL_SCREEN :
    return {
        ...state,
        isFullScreen: !state.isFullScreen
    };
case actionTypes.FULL_SCREEN_EXIT:
    return {
        ...state,
        isFullScreen: false
    };
case actionTypes.CHANGE_LAYOUT:
    return {
        ...state,
        layout: action.layout
    };
  //fin template
  default:
    return state
  }
}

export default agentReducer